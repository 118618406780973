import { call, put, delay, select, takeLatest } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';

import { FETCH_SETTINGS_REQUEST, SYNC_SETTINGS } from './redux.actionTypes';
import {
    syncSettings,
    setSettingsData,
    resetSettingsFlag
} from './redux.actions';
import { settingsSelector, settingsChangedSelector } from './redux.selectors';

// Worker sagas
function* fetchSettings() {
    try {
        const { data: { data: [{ settings } = {}] = [] } = {} } = yield call(
            apiRequest,
            {
                method: 'get',
                url: 'UserSettings/getSettings'
            }
        );
        if (settings) {
            yield put(setSettingsData(JSON.parse(settings)));
        }
    } catch (error) {
        console.log('Error fetching settings:', error);
    }
}

function* syncSettingsWorker() {
    const settingsChanged = yield select(settingsChangedSelector);
    if (settingsChanged) {
        try {
            const settings = yield select(settingsSelector);
            yield call(apiRequest, {
                method: 'put',
                url: 'UserSettings/syncSettings',
                data: {
                    settings: JSON.stringify(settings)
                }
            });
            yield put(resetSettingsFlag());
        } catch (error) {
            yield put(resetSettingsFlag());
            console.error('Error syncing settings:', error);
        }
    }
}

// Watcher sagas
function* watchSyncSettings() {
    while (true) {
        yield delay(180000); // 3 minutes
        yield put(syncSettings());
    }
}

export default function*() {
    yield takeLatest(FETCH_SETTINGS_REQUEST, fetchSettings);
    yield takeLatest(SYNC_SETTINGS, syncSettingsWorker);
    yield watchSyncSettings();
}
