import makeContext from '@libs/makeContext';

const initialState = {
    chartSettings: {},
    data: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'initialize': {
            const { payload: initialSettings } = action;

            return {
                ...state,
                chartSettings: initialSettings
            };
        }

        case 'updateSettings': {
            const { payload } = action;

            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    ...payload
                }
            };
        }

        case 'hideChart': {
            const { payload: chartName } = action;

            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    [chartName]: false
                }
            };
        }

        default: {
            return state;
        }
    }
};

const middleware = (state, action, { storeChartViewSettings }) => {
    const { chartSettings } = reducer(state, action);
    storeChartViewSettings(chartSettings);

    return action;
};

const [
    DetailsBreakdownProvider,
    useDetailsBreakdownChartState,
    useDetailsBreakdownChartDispatch,
    useDetailsBreakdownChart
] = makeContext(reducer, initialState, {
    name: 'breakdownChart',
    middleware
});
export {
    useDetailsBreakdownChart as default,
    DetailsBreakdownProvider,
    useDetailsBreakdownChartState,
    useDetailsBreakdownChartDispatch
};
