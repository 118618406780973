import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
// Material
import { withStyles } from '@material-ui/core';
// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { makeSideEffectCallback } from '@libs/makeContext';

import Adornments from './Adornments';
import Content from './Content';
import useDetailsBreakdown from './hooks';
import { DetailsBreakdownProvider } from './useDetailsBreakdownChart';

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    data: PropTypes.array,
    retailer: PropTypes.string,
    chartSettings: PropTypes.object,
    storeChartViewSettings: PropTypes.func
};

const defaultProps = {
    chartSettings: {},
    storeChartViewSettings: () => {}
};

const SampleDetailsBreakdownChart = withStyles(styles)(
    ({ classes: c, data, retailer, chartSettings, storeChartViewSettings }) => {
        const breakdownData = useDetailsBreakdown(data, retailer);
        const [action, dispatch] = useState({});

        useEffect(() => {
            if (Object.keys(chartSettings).length === 0 && breakdownData) {
                const initialSettings = Object.keys(breakdownData).reduce(
                    (acm, chartName) => ({ ...acm, [chartName]: true }),
                    {}
                );

                dispatch({ type: 'initialize', payload: initialSettings });
            }
        }, [chartSettings, breakdownData]);

        const initialize = useCallback(
            state => ({
                ...state,
                data: breakdownData,
                chartSettings
            }),
            [breakdownData, chartSettings]
        );

        const middlewareProps = useMemo(
            () => ({
                storeChartViewSettings: makeSideEffectCallback(
                    storeChartViewSettings
                )
            }),
            [storeChartViewSettings]
        );

        return (
            <DetailsBreakdownProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Breakdown'
                    AdornmentComponent={<Adornments />}
                    styles={{ container: c.container }}
                >
                    <Content />
                </ContentContainer>
            </DetailsBreakdownProvider>
        );
    }
);

SampleDetailsBreakdownChart.propTypes = propTypes;
SampleDetailsBreakdownChart.defaultProps = defaultProps;

export default SampleDetailsBreakdownChart;
