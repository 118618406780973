import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Checkbox from '@baseComponents/Checkbox';

const CheckboxRow = ({ checked, label, onChange, classes }) => (
    <div className={classes.contentItem} onClick={onChange}>
        <Checkbox checked={checked} color='primary' />
        <Typography className={classes.checkboxLabel}>{label}</Typography>
    </div>
);

CheckboxRow.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default CheckboxRow;
