import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

import { LegendItem } from '@libComponents/Charts';

import { FORM_NAME } from '../common';
import OverlayChartContainer from '../OverlayChartContainer';

const styles = ({ spacing, palette }) => ({
    root: {
        width: '90%',
        margin: 'auto'
    },
    legendContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    itemContainer: {
        '&:hover': {
            cursor: 'pointer',
            '& $value': {
                color: palette.orange.main
            },
            '& $name': {
                color: palette.orange.main
            }
        }
    },
    total: {
        fontSize: spacing.unit * 2,
        color: '#B71C1C',
        textAlign: 'center',
        marginBottom: spacing.unit * 1
    },
    name: {
        maxWidth: 'var(--name-max-width)'
    },
    nameHighlighted: {
        maxWidth: 'var(--name-max-width)',
        color: palette.orange.dark
    },
    value: {},
    valueHighlighted: {
        color: palette.orange.dark
    }
});

const mapStateToProps = state => ({
    retailer: formValueSelector(FORM_NAME)(state, 'retailer')
});

const mapDispatchToProps = dispatch => ({
    onLegendItemClick: retailerId =>
        dispatch(change(FORM_NAME, 'retailer', retailerId))
});

const Legend = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: c,
        theme: { spacing },
        payload,
        screen,
        retailer,
        onLegendItemClick
    }) => {
        const totalValue = useMemo(
            () => payload.reduce((sum, item) => sum + item.payload.value, 0),
            [payload]
        );
        const maxWidth =
            screen === 'lg'
                ? spacing.unit * 24
                : screen === 'md'
                ? spacing.unit * 20
                : spacing.unit * 16;

        const handleLegendItemClick = useCallback(
            selectedValue => () => {
                const value =
                    retailer && retailer === selectedValue ? '' : selectedValue; //
                onLegendItemClick(value);
            },
            [onLegendItemClick, retailer]
        );

        return (
            <div
                className={c.root}
                style={{ '--name-max-width': maxWidth + 'px' }}
            >
                <Typography className={c.total} variant='body1'>
                    Total: {totalValue}
                </Typography>
                <OverlayChartContainer>
                    <div className={c.legendContainer}>
                        {payload.map((item, index) => (
                            <LegendItem
                                key={item.value}
                                color={item.color}
                                name={item.value}
                                value={item.payload.value}
                                separator={index < payload.length - 1}
                                styles={{
                                    container: c.itemContainer,
                                    value:
                                        retailer === item.value
                                            ? c.valueHighlighted
                                            : c.value,
                                    name:
                                        retailer === item.value
                                            ? c.nameHighlighted
                                            : c.name
                                }}
                                onClick={handleLegendItemClick(item.value)}
                            />
                        ))}
                    </div>
                </OverlayChartContainer>
            </div>
        );
    }
);

export default Legend;
