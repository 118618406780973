import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Libs
import { GridItem } from '@formComponents';

import BreakdownChart from './BreakdownChart';
import NoDataMessage from '../../NoDataMessage';
import { useResponsivePieChartSize } from '../../hooks';
import { useDetailsBreakdownChartState } from '../useDetailsBreakdownChart';

const radiuses = {
    sm: {
        outerRadius: 110,
        innerRadius: 30
    },
    md: {
        outerRadius: 150,
        innerRadius: 40
    },
    lg: {
        outerRadius: 200,
        innerRadius: 60
    }
};

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        minHeight: spacing.unit * 20
    },
    gridItem: {
        padding: spacing.unit * 1.5
    }
});

const Content = withStyles(styles)(({ classes: c }) => {
    const { data, chartSettings } = useDetailsBreakdownChartState();
    const [screen, ref] = useResponsivePieChartSize();

    return (
        <div ref={ref} className={c.container}>
            {data && Object.keys(data).length > 0 ? (
                <Grid container>
                    {Object.entries(data).map(
                        ([key, value]) =>
                            chartSettings[key] !== false && (
                                <GridItem
                                    key={key}
                                    md={6}
                                    className={c.gridItem}
                                >
                                    <BreakdownChart
                                        chartName={key}
                                        data={value}
                                        screen={screen}
                                        {...radiuses[screen]}
                                    />
                                </GridItem>
                            )
                    )}
                </Grid>
            ) : (
                <NoDataMessage />
            )}
        </div>
    );
});

export default Content;
