import React, { useCallback } from 'react';
import { PieChart } from '@libComponents/Charts';
import ContentContainer from '@libComponents/ContentContainer';

import Legend from './LegendContainer';
import ChartHideAdornments from './ChartHideAdornments';
import useDynamicColors from '../../../hooks';
import { useDetailsBreakdownChartDispatch } from '../../useDetailsBreakdownChart';

const BreakdownChart = ({ chartName, data, ...props }) => {
    const dispatch = useDetailsBreakdownChartDispatch();
    const colors = useDynamicColors(data);

    const handeChartHideClick = useCallback(chartName => {
        dispatch({ type: 'hideChart', payload: chartName });
    }, []);

    return (
        <ContentContainer
            title={chartName}
            AdornmentComponent={
                <ChartHideAdornments
                    onClick={() => handeChartHideClick(chartName)}
                />
            }
        >
            <PieChart
                data={data}
                colors={colors}
                legendRenderer={Legend}
                layout='vertical'
                align='right'
                verticalAlign='middle'
                {...props}
            />
        </ContentContainer>
    );
};

export default BreakdownChart;
