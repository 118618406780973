import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@libComponents/Button';

const ModalFooter = ({ onSave, classes, disabled }) => (
    <div className={classes.footer}>
        <Button
            variant='contained'
            color='primary'
            onClick={onSave}
            className={classes.button}
            disabled={disabled}
        >
            Save
        </Button>
    </div>
);

ModalFooter.propTypes = {
    onSave: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default ModalFooter;
