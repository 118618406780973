import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { DESIGN_DEPARTMENT } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'designDepartment',
    label: 'Design Dept',
    placeholder: 'Select Design Dept',
    disabled: false,
    required: false
};

const SelectDesignDepartments = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={DESIGN_DEPARTMENT} />
);

SelectDesignDepartments.propTypes = propTypes;
SelectDesignDepartments.defaultProps = defaultProps;

export { SelectDesignDepartments as default, SelectDesignDepartments };
