import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { ModalHeader, ModalContent } from '@libComponents/Modal';

import { samplingReportBreakdownKeys } from '../../../hooks';
import useDetailsBreakdownChart from '../../../useDetailsBreakdownChart';
import CheckboxRow from './CheckboxRow';
import SelectAllRow from './SelectAllRow';
import ModalFooter from './ModalFooter';

const propTypes = {
    onClose: PropTypes.func.isRequired
};

const styles = ({ breakpoints, spacing, typography }) => ({
    content: {
        width: breakpoints.values.sm,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: spacing.unit * 2,
        paddingTop: spacing.unit * 1
    },
    selectAllRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    checkboxLabel: { fontSize: typography.caption.fontSize },
    contentGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    contentItem: {
        flex: '1 0 25%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: '100%',
        margin: spacing.unit * 2,
        marginTop: 0
    }
});

const ManageChartsVisibilityModal = withStyles(styles)(
    ({ classes: c, onClose }) => {
        const [{ chartSettings }, dispatch] = useDetailsBreakdownChart();
        const [localChartSettings, setLocalChartSettings] = useState(
            chartSettings
        );
        const [selectAll, setSelectAll] = useState(false);

        const canSave = useMemo(() => {
            const someSelected = Object.values(localChartSettings).some(
                Boolean
            );
            return someSelected;
        }, [localChartSettings]);

        useEffect(() => {
            const allSelected = Object.values(localChartSettings).every(
                Boolean
            );
            setSelectAll(allSelected);
        }, [localChartSettings]);

        const handleCheckboxChange = chartName => {
            setLocalChartSettings(prevSettings => ({
                ...prevSettings,
                [chartName]: !prevSettings[chartName]
            }));
        };

        const handleSelectAllChange = () => {
            const newSelectAll = !selectAll;
            const updatedSettings = Object.fromEntries(
                Object.entries(localChartSettings).map(([key]) => [
                    key,
                    newSelectAll
                ])
            );
            setLocalChartSettings(updatedSettings);
            setSelectAll(newSelectAll);
        };

        const onSaveButtonClick = () => {
            dispatch({
                type: 'updateSettings',
                payload: localChartSettings
            });
            onClose();
        };

        return (
            <>
                <ModalHeader onClose={onClose}>
                    Manage Charts Visibility
                </ModalHeader>
                <ModalContent className={c.content}>
                    <SelectAllRow
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        classes={c}
                    />
                    <div className={c.contentGrid}>
                        {samplingReportBreakdownKeys.map(({ name, label }) => (
                            <CheckboxRow
                                key={name}
                                checked={localChartSettings[label]}
                                label={label}
                                onChange={() => handleCheckboxChange(label)}
                                classes={c}
                            />
                        ))}
                    </div>
                </ModalContent>
                <ModalFooter
                    onSave={onSaveButtonClick}
                    classes={c}
                    disabled={!canSave}
                />
            </>
        );
    }
);

ManageChartsVisibilityModal.propTypes = propTypes;

export default ManageChartsVisibilityModal;
