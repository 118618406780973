import React, { useMemo } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { GridItem } from '@formComponents';
import { Date as DateInput } from '@formComponents';
import {
    denormalizeDate,
    deserializeDate
} from '@baseComponents/Date/normalizeDate';

import { FORM_NAME } from './common';

const styles = () => ({
    container: {
        width: '50%',
        height: '100%'
    },
    inputRoot: {
        backgroundColor: 'white'
    }
});

const mapStateToProps = state => ({
    startDate: formValueSelector(FORM_NAME)(state, 'startDate')
});

const DateRangeFilter = compose(
    connect(
        mapStateToProps,
        null
    ),
    withStyles(styles)
)(({ classes: c, startDate }) => {
    const endDateMax = useMemo(() => {
        const date = new Date(deserializeDate(startDate));
        date.setDate(date.getDate() + 90);
        return date;
    }, [startDate]);

    const dateInputProps = {
        label: '',
        styles: { root: c.inputRoot }
    };

    return (
        <Grid container className={c.container}>
            <GridItem md={6}>
                <DateInput
                    name='startDate'
                    placeholder='From'
                    minDate={new Date('2015-01-01')}
                    {...dateInputProps}
                />
            </GridItem>
            <GridItem md={6}>
                <DateInput
                    name='endDate'
                    placeholder='To'
                    minDate={denormalizeDate(startDate)}
                    maxDate={endDateMax}
                    {...dateInputProps}
                />
            </GridItem>
        </Grid>
    );
});

export default DateRangeFilter;
