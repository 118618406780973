import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';

import { Divider, Grid, withStyles } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import {
    Checkbox,
    Input,
    GridContainer,
    GridItem,
    Date as DateInput
} from '@formComponents';
import { fetchDatasets } from '@libs/datasets';
import { formListener } from 'app/ReduxGate/store/middleware';
import { requestedDateAfterNewImplementation } from '@baseComponents/Date';

// Other domain
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import {
    SelectGenders,
    SelectAges,
    SelectCategories,
    SelectStyles,
    SelectShapeDesigners,
    SelectDesignDepartments
} from 'common/components';
import { setGenderChildren as setShapeGenderChildren } from 'common/redux.actions';
import { isPrimarkSelector } from 'cadUpload/redux.selectors';

// Local
import asyncValidate from './asyncValidate';
import HeaderAdornment from '../HeaderAdornment';
import {
    getCopyShapeData,
    setShapeRetailerChildren,
    setFabricOptionChildren,
    setSampleFitOptionsChildren
} from '../../../redux.actions';
import {
    SelectCountries,
    SelectDepartments,
    SelectDesignSources,
    SelectRetailers,
    SelectSendToEcho,
    SelectSendToBuyer,
    SamplePaginatedSelect,
    RequestedDate,
    SampleSelect,
    SelectSeasons
} from '../../../components';
import { isInHouseDesignSourceSelector } from '../../../redux.selectors';
import { FORM_NAME, LABELS } from '../../common';
import { useShapeState } from '../../useShape';
import { useRequiredByMinDate } from './hooks';
import {
    SHAPE_COPY_SAMPLES,
    GET_SAMPLE_SIZES,
    FIT_OPTIONS
} from '../../../redux.datasets';
import { SET_HAS_MANUAL_REF_CHILDREN } from '../../../redux.actionTypes';

const styles = ({ spacing, typography }) => ({
    container: {
        paddingBottom: spacing.unit * 6
    },
    divider: {
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    },

    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const mapState = state => ({
    isInHouse: isInHouseDesignSourceSelector(state),
    ...formValueSelector(FORM_NAME)(
        state,
        'hasManualReference',
        'hasCopyReference',
        'copyReference',
        'dateRequested',
        'hasFabricOption'
    ),
    isPrimark: isPrimarkSelector(state, FORM_NAME)
});

const mapDispatch = {
    fetchDatasets
};
const mapInputs = {
    hasManualReference: ({ payload }) => ({
        type: SET_HAS_MANUAL_REF_CHILDREN,
        payload,
        meta: { formName: FORM_NAME }
    }),
    manualReference: ({ payload }) => {
        if (payload) return change(FORM_NAME, 'manualReferenceAsync', '');
    },
    hasCopyReference: ({ payload }) => {
        if (!payload) return change(FORM_NAME, 'copyReference', '');
    },
    hasFabricOption: ({ payload }) =>
        setFabricOptionChildren({
            formName: FORM_NAME,
            hasFabricOption: payload
        }),
    copyReference: ({ payload }) => getCopyShapeData(payload),
    retailer: setShapeRetailerChildren,
    gender: ({ payload }) => setShapeGenderChildren(payload, FORM_NAME),
    fitOption: ({ payload }) => setSampleFitOptionsChildren(payload, FORM_NAME)
};

const Setup = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['manualReference']
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        hasManualReference,
        hasCopyReference,
        dateRequested,
        isInHouse,
        fetchDatasets,
        hasFabricOption,
        isPrimark
    }) => {
        const { setupLocked, isSealed, mode } = useShapeState();
        const isDisabled = setupLocked || hasFabricOption;
        const requiredByMinDate = useRequiredByMinDate(dateRequested);
        const shouldDisabledOnNew = mode === 'new' && isDisabled;
        const shouldDisabledOnAmendment =
            mode !== 'new' &&
            requestedDateAfterNewImplementation(dateRequested);

        useEffect(() => {
            fetchDatasets([GET_SAMPLE_SIZES, DEVELOPMENT_TYPES]);
        }, []);
        return (
            <ContentContainer
                title={LABELS.setup}
                AdornmentComponent={<HeaderAdornment />}
            >
                <GridContainer>
                    <GridItem md={12}>
                        <Grid container>
                            <GridItem md={4}>
                                <SelectRetailers
                                    required
                                    disabled={isDisabled}
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectGenders
                                    required
                                    disabled={
                                        shouldDisabledOnNew ||
                                        shouldDisabledOnAmendment
                                    }
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectAges
                                    required
                                    disabled={
                                        shouldDisabledOnNew ||
                                        shouldDisabledOnAmendment
                                    }
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectCategories
                                    required
                                    disabled={
                                        shouldDisabledOnNew ||
                                        shouldDisabledOnAmendment
                                    }
                                />
                            </GridItem>

                            <GridItem md={4}>
                                <SelectDepartments
                                    required
                                    disabled={
                                        shouldDisabledOnNew ||
                                        shouldDisabledOnAmendment
                                    }
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectStyles
                                    required
                                    disabled={
                                        shouldDisabledOnNew ||
                                        shouldDisabledOnAmendment
                                    }
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectDesignSources required />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectCountries
                                    required
                                    disabled={hasFabricOption}
                                />
                            </GridItem>

                            {isPrimark && (
                                <GridItem md={4}>
                                    <SelectDesignDepartments required />
                                </GridItem>
                            )}

                            {isInHouse && (
                                <GridItem md={4}>
                                    <SelectShapeDesigners
                                        required
                                        disabled={mode === 'amend'}
                                    />
                                </GridItem>
                            )}
                        </Grid>
                        {mode === 'new' && (
                            <Fragment>
                                <Divider className={c.divider} />

                                <Grid container>
                                    <GridItem md={4}>
                                        <Checkbox
                                            name='hasFabricOption'
                                            label='Fabric option'
                                            styles={{ label: c.checkboxLabel }}
                                            disabled={!!setupLocked}
                                        />
                                    </GridItem>
                                    <GridItem md={4}>
                                        <Checkbox
                                            name='hasCopyReference'
                                            label='Copy data from another sample request'
                                            styles={{ label: c.checkboxLabel }}
                                            disabled={isDisabled}
                                        />
                                    </GridItem>
                                    <GridItem md={4}>
                                        <Checkbox
                                            name='hasManualReference'
                                            label='I want to give this a manual reference'
                                            styles={{ label: c.checkboxLabel }}
                                            disabled={isDisabled}
                                        />
                                    </GridItem>
                                    <GridItem md={4} />
                                    <GridItem md={4}>
                                        {hasCopyReference &&
                                            (setupLocked ? (
                                                <Input
                                                    name='copyReference'
                                                    placeholder=' '
                                                    required
                                                    disabled={true}
                                                />
                                            ) : (
                                                <SamplePaginatedSelect
                                                    name='copyReference'
                                                    entity={SHAPE_COPY_SAMPLES}
                                                    isCancellable={false}
                                                    required
                                                />
                                            ))}
                                    </GridItem>
                                    <GridItem md={4}>
                                        {hasManualReference && (
                                            <Input
                                                name='manualReference'
                                                placeholder='Enter manual reference'
                                                required
                                                disabled={!!setupLocked}
                                                autoFocus={!hasFabricOption}
                                            />
                                        )}
                                    </GridItem>
                                </Grid>
                            </Fragment>
                        )}
                        <Divider className={c.divider} />
                    </GridItem>
                </GridContainer>

                <GridContainer className={c.container}>
                    <GridItem md={12}>
                        <Grid container>
                            <GridItem md={4}>
                                <RequestedDate />
                            </GridItem>
                            <GridItem md={4}>
                                <DateInput
                                    name='dateRequiredBy'
                                    label='Sample required by'
                                    minDate={requiredByMinDate}
                                    required={isSealed ? false : true}
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SampleSelect
                                    name='fitOption'
                                    label='Fit Options'
                                    placeholder='Select fit option'
                                    entity={FIT_OPTIONS}
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectSendToEcho formname={FORM_NAME} />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectSendToBuyer formname={FORM_NAME} />
                            </GridItem>
                            <GridItem md={4}>
                                <Input name='buyer' label='Buyer' />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectSeasons />
                            </GridItem>
                        </Grid>
                    </GridItem>
                </GridContainer>
            </ContentContainer>
        );
    }
);

export default Setup;
