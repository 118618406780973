import React from 'react';

import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

import { RETAILERS, DEPARTMENTS, COUNTRIES } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { DESIGN_DEPARTMENT } from 'common/redux.datasets';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';

import {
    ORDER_STATUS,
    PO_PACK_TYPE,
    CP_SHIPMENT_DELAY_REASON,
    ORDER_BASE_FACTORIES,
    SETTLEMENT_DISCOUNT
} from 'orders/redux.datasets';
import { DOMAIN_NAME as ORDERS_DOMAIN_NAME } from 'orders/common';
import { ReferenceLinkModal } from './components';

export const DATASET_DOMAINS = [ORDERS_DOMAIN_NAME, SAMPLING_DOMAIN_NAME];

export const DATASETS = [
    RETAILERS,
    DEPARTMENTS,
    ORDER_STATUS,
    COUNTRIES,
    PO_PACK_TYPE,
    CP_SHIPMENT_DELAY_REASON,
    ORDER_BASE_FACTORIES,
    SETTLEMENT_DISCOUNT,
    DESIGN_DEPARTMENT
];

export const PAGE_SIZES = [10, 50, 100, 300];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number'
        },
        {
            name: 'orderNo',
            header: 'Order No',
            filterable: true,
            type: 'text',
            render: ({ column, row }) => (
                <ReferenceLinkModal
                    value={row[column.name]}
                    reference={row[column.name]}
                />
            )
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: RETAILERS
        },
        {
            name: 'departmentId',
            header: 'Department',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: DEPARTMENTS
        },
        {
            name: 'orderDate',
            header: 'JWP Placed Date',
            filterable: true,
            type: 'date'
        },
        {
            name: 'factoryRef',
            header: 'JWP No',
            filterable: true,
            type: 'text'
        },
        {
            name: 'orderStatusId',
            header: 'PO Status',
            filterable: true,
            editable: true,
            type: 'select',
            domain: ORDERS_DOMAIN_NAME,
            entity: ORDER_STATUS
        },
        {
            name: 'orderStatus',
            header: 'Order Status',
            filterable: true,
            type: 'text'
        },
        {
            name: 'poNo',
            header: 'PO No',
            filterable: true,
            type: 'text'
        },
        {
            name: 'poQuantity',
            header: 'Quantity',
            filterable: true,
            type: 'number'
        },
        {
            name: 'sampleRef',
            header: 'Sample Ref',
            filterable: true,
            type: 'text',
            sortable: false
        },
        {
            name: 'colour',
            header: 'Colour',
            filterable: true,
            type: 'text'
        },
        {
            name: 'fobdt',
            header: 'Fob Date',
            filterable: true,
            type: 'date'
        },
        {
            name: 'actPoRcvdDt',
            header: 'Actual PO Received Date',
            editable: true,
            type: 'date'
        },
        {
            name: 'asosDepartment',
            header: 'Sub Department',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'packType',
            header: 'Pack Type',
            type: 'text',
            filterable: true
        },
        {
            name: 'cid',
            header: 'Consolidated PO No',
            type: 'text',
            visible: false
        },
        {
            name: 'clientPoNo',
            header: 'Customer PO No (Extranet)',
            type: 'text',
            visible: false
        },
        {
            name: 'commodity',
            header: 'Commodity',
            type: 'text',
            visible: false
        },
        {
            name: 'countryId',
            header: 'Country',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'departmentNo',
            header: 'Department No',
            type: 'text',
            visible: false
        },
        {
            name: 'destination',
            header: 'Destination',
            filterable: true,
            type: 'text'
        },
        {
            name: 'directStatus',
            header: 'Direct/Indirect',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'dtPrChkPo',
            header: 'Date Price Checked Against PO',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'exFtyDate',
            header: 'Ex Fty',
            filterable: true,
            type: 'date'
        },
        {
            name: 'fabricConsumption',
            header: 'Fabric Consumption',
            type: 'text',
            visible: false
        },
        {
            name: 'factReqExtDt',
            header: 'Fty Requested Ex Date',
            editable: true,
            type: 'date'
        },
        {
            name: 'initialHoDt',
            header: 'Initial Handover Date',
            editable: true,
            filterable: true,
            isCellEditable: row => !row.initialHoDt,
            type: 'date'
        },
        {
            name: 'kimbelNo',
            header: 'Kimball No',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'kimbleColour',
            header: 'Kimball Colour',
            type: 'text',
            visible: false
        },
        {
            name: 'lblstatus',
            header: 'Section Number-Box End Label Colour',
            type: 'text',
            visible: false
        },
        {
            name: 'licConceptApprDt',
            header: 'Licensor Concept Approval Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'licenseRef',
            header: 'Licensor/Graphic Ref',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'licenseUsaFriendly',
            header: 'License USA Friendly',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'licensingCompany',
            header: 'Licensing Company',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'licGarmentApprDt',
            header: 'Licensor Garment Approval Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'licPoNo',
            header: 'Licensor PO No',
            type: 'text',
            editable: true,
            filterable: true,
            visible: false
        },
        {
            name: 'licPoReDt',
            header: 'Licensor PO Received',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'noLicPrd',
            header: 'No of Licensor Production Samples Required',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'optionId',
            header: 'Option Id',
            type: 'text',
            visible: false
        },
        {
            name: 'factoryId',
            header: 'Factory',
            filterable: true,
            type: 'select',
            domain: ORDERS_DOMAIN_NAME,
            entity: ORDER_BASE_FACTORIES
        },
        {
            name: 'ordInfoSntLic',
            header: 'Order Info Sent to Licensor',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'packId',
            header: 'Pack Id',
            type: 'text',
            visible: false
        },
        {
            name: 'poComments',
            header: 'Comments',
            editable: true,
            type: 'text'
        },
        {
            name: 'poMode',
            header: 'Shipping Mode',
            type: 'text'
        },
        {
            name: 'poPackType',
            header: 'PO Pack',
            type: 'text'
        },
        {
            name: 'poRcvdByDt',
            header: 'PO Received By Date',
            editable: true,
            type: 'date'
        },
        {
            name: 'retailPrice',
            header: 'Retail Price',
            type: 'number',
            visible: false
        },
        {
            name: 'orderType',
            header: 'Order Type',
            type: 'text',
            filterable: true
        },
        {
            name: 'poType',
            header: 'PO Type',
            type: 'text'
        },
        {
            name: 'productDescription',
            header: 'Product Description',
            type: 'text',
            visible: false
        },
        {
            name: 'productSuppRef',
            header: 'Product Supplier Ref',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'psdComplete',
            header: 'PSD Completed & Sent to Customer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'ratioQty',
            header: 'Ratio Qty/Carton',
            type: 'number',
            visible: false
        },
        {
            name: 'season',
            header: 'Season',
            type: 'text'
        },
        {
            name: 'gender',
            header: 'Gender',
            filterable: true,
            type: 'text'
        },
        {
            name: 'category',
            header: 'Category',
            filterable: true,
            type: 'text'
        },
        {
            name: 'style',
            header: 'Style',
            filterable: true,
            type: 'text'
        },
        {
            name: 'age',
            header: 'Age',
            filterable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'styleNumber',
            header: 'Style No',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'swcode',
            header: 'Swing Ticket',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'totalFabricKg',
            header: 'Total Fabric Consumption',
            type: 'text',
            visible: false
        },
        {
            name: 'totalYarnPrice',
            header: 'Total Yarn Price',
            type: 'text',
            visible: false
        },
        {
            name: 'trlAprSnt',
            header: 'Licensor Trl Sent',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'trlPlnDt',
            header: 'Licensor Trl Requested',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'updatedHoDt',
            header: 'Updated Handover Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'yarnCount',
            header: 'Yarn Count',
            type: 'text',
            visible: false
        },
        {
            name: 'yarnPrice',
            header: 'Yarn Price',
            type: 'text',
            visible: false
        },

        {
            name: 'actSealDt',
            header: 'Actual Sealed Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'bcDelivered',
            header: 'Barcode Delivered Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'bcCodeRcvd',
            header: 'Barcode Rcvd Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'bcOrdered',
            header: 'Barcode Order Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'bulkLabTstRptRcvdDt',
            header: 'Bulk & Base Lab Test Rpt Rcvd Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'colourCode',
            header: 'Colour Code',
            type: 'text'
        },
        {
            name: 'conceptReference',
            header: 'Concept Reference',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'cusAprDt',
            header: 'Customer Aproval Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'cutDt',
            header: 'Cutting',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'dyeDt',
            header: 'Dyeing',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'fabCom',
            header: 'Fabric Composition',
            type: 'text'
        },
        {
            name: 'fabGsm',
            header: 'Fabric Weight',
            type: 'text'
        },
        {
            name: 'fabrication',
            header: 'Fabrication',
            type: 'text',
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'fabricType',
            header: 'Fabric Finish',
            type: 'text'
        },
        {
            name: 'finishDt',
            header: 'Finishing',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'fitSampleAprDt',
            header: 'Fit Sample Aproved Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'fitSampleSendDt',
            header: 'Fit Sample Send Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'styleDescription',
            header: 'Style Description',
            filterable: true,
            type: 'text'
        },
        {
            name: 'goldAprRcvd',
            header: 'Gold Seal Approval Buyer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'goldSealAppovalStatus',
            header: 'Gold Seal Approval Status',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'goldSealSndByDt',
            header: 'Gold Seal Send By Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'goldSealSndDt',
            header: 'Gold Seal Send Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'goldSealUpdateLhDt',
            header: 'Gold Seal Updated Date in LH',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'goldSntByr',
            header: 'Gold Seal Sent to Buyer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'hoPackedQty',
            header: 'Final Inspected Qty',
            editable: true,
            type: 'number',
            visible: false
        },
        {
            name: 'inspectionDt',
            header: 'Inspection',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'insResult',
            header: 'Inspection Result',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'knitDt',
            header: 'Knitting',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'labDipApprovedByCustomer',
            header: 'Lab Dip Approved By Customer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'labDipRef',
            header: 'Lab Dip /Eld',
            type: 'text',
            visible: false
        },
        {
            name: 'labDipSentToCustomer',
            header: 'Lab Dip Sent To Customer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'labTstRptRcvdByDt',
            header: 'Lab Test Report Receive by Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'lightHouseAprDt',
            header: 'Lighthouse Update & Approval Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'nlKind',
            header: 'Nl Kind',
            type: 'text',
            visible: false
        },
        {
            name: 'plnFitSamSndDt',
            header: 'Planned Fit Sample Send by Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'plnSealByDt',
            header: 'Planned Seal by Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'prdaprdt',
            header: 'Aproval to Production',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'printaprdt',
            header: 'Print Approved Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'printDt',
            header: 'Printing',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'psdAprLic',
            header: 'Production Sample Approval Licensor',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'psdSntLic',
            header: 'Production Sample Sent to Licensor',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'samSntFac',
            header: 'Sample Request Sent to Factory',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'samSntFromBd',
            header: 'Sample Sent From BD',
            type: 'date',
            render: ({ column, row }) => {
                const cellValue = row[column.name];
                return (
                    cellValue &&
                    cellValue
                        .split(',')
                        .map(formatDateToShort)
                        .join(', ')
                );
            },
            sortable: false,
            visible: false
        },
        {
            name: 'samSntFromUk',
            header: 'Sample Request Sent From UK',
            type: 'date',
            render: ({ column, row }) => {
                const cellValue = row[column.name];
                return (
                    cellValue &&
                    cellValue
                        .split(',')
                        .map(formatDateToShort)
                        .join(', ')
                );
            },
            sortable: false,
            visible: false
        },
        {
            name: 'sewDt',
            header: 'Sewing',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'sewLineNo',
            header: 'Sewing Line & Floor No',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'specialComments',
            header: 'Special Comments',
            type: 'text',
            visible: false
        },
        {
            name: 'specialFabricRef',
            header: 'Special Fabric Ref',
            type: 'text'
        },
        {
            name: 'specRef',
            header: 'Spec Ref',
            filterable: true,
            type: 'text'
        },
        {
            name: 'spellChkApr',
            header: 'Spell Check Approval Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'sustainability',
            header: 'Sustainable',
            type: 'text',
            visible: false
        },
        {
            name: 'transactionCertNo',
            header: 'Transaction Cert No',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'washActDt',
            header: 'Wash Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'washAprDt',
            header: 'Wash Approved Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'wbSntDt',
            header: 'Workbook Sent to Customer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'yarnDt',
            header: 'Yarn Date',
            editable: true,
            type: 'date',
            visible: false
        },

        {
            name: 'actEtaUk',
            header: 'Act Eta Uk',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'actExBdDt',
            header: 'Act Ex BD',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'actPsSndDt',
            header: 'PS Actual Sent Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'asnNo',
            header: 'ASN No',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'asnRcvdDt',
            header: 'ASN Received Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'baRcvdDt',
            header: 'BA Received Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'blNo',
            header: 'BL No',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'bookingDt',
            header: 'Forwarder Booking Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'bookingNo',
            header: 'Forwarder Booking No',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'cartonQty',
            header: 'Carton Qty',
            editable: true,
            type: 'number',
            visible: false
        },
        {
            name: 'customerHoDt',
            header: 'Customer H/O Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'delGoodsEtdDt',
            header: 'Delivered Goods ETD',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'echoShippedValue',
            header: 'Echo Invoice Value',
            type: 'number',
            visible: false
        },
        {
            name: 'excessQty',
            header: 'Excess Qty',
            type: 'number',
            visible: false
        },
        {
            name: 'factoryShippedValue',
            header: 'Factory Invoice Value',
            type: 'number',
            visible: false
        },
        {
            name: 'forwarderCutOffDt',
            header: 'Forwarder Cut off Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'ftyInvDt',
            header: 'Factory Invoice Date',
            editable: true,
            filterable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'ftyInvoice',
            header: 'Factory Invoice No',
            filterable: true,
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'gccTestUpload',
            header: 'Gcc Test Report Upload Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'initHodByr',
            header: 'Initial Agreed Hod With Buyer',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'mawbNo',
            header: 'Master Warehouse Bill',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'okTsRcvdDt',
            header: 'Okts Received Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'packListUp',
            header: 'Packing List Update Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'psAprStatus',
            header: 'Ps Approval Status',
            editable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'psSndDt',
            header: 'PS Send By',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'samSntForTstDt',
            header: 'Sample Sent For Testing',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'shipmentDelayStatusId',
            header: 'Shipment Delay Status',
            editable: true,
            type: 'select',
            domain: ORDERS_DOMAIN_NAME,
            entity: CP_SHIPMENT_DELAY_REASON
        },
        {
            name: 'shippedQty',
            header: 'Total Shipped Qty',
            editable: true,
            type: 'number'
        },
        {
            name: 'shippingStatus',
            header: 'Shipping Status',
            type: 'text'
        },
        {
            name: 'shortQty',
            header: 'Short Qty',
            type: 'number',
            visible: false
        },
        {
            name: 'tstRptAprDt',
            header: 'Test Report Approved Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'vesselAuthor',
            header: 'Vessel Authorization',
            editable: true,
            type: 'text',
            visible: false
        },

        {
            name: 'actHoDt',
            header: 'Act H/O Date',
            editable: true,
            filterable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'additionalCost',
            header: 'Misc. Cost',
            editable: true,
            type: 'number',
            visible: false
        },
        {
            name: 'commisonInPcs',
            header: 'Unit Commission',
            type: 'number',
            visible: false
        },
        {
            name: 'currency',
            header: 'Currency',
            type: 'text',
            visible: false
        },
        {
            name: 'discount',
            header: 'Unit Sales Discount',
            type: 'number',
            visible: false
        },
        {
            name: 'echoInvDt',
            header: 'Echo Invoice Date',
            editable: true,
            filterable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'echoInvNo',
            header: 'Echo Invoice No',
            editable: true,
            filterable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'echoMargin',
            header: 'Unit Margin',
            type: 'number',
            visible: false
        },
        {
            name: 'factoryCost',
            header: 'Unit Factory Cost',
            type: 'number',
            visible: false
        },
        {
            name: 'fobAfterDis',
            header: 'Fob After Discount',
            type: 'number',
            visible: false
        },
        {
            name: 'finalNetPrice',
            header: 'Unit Net Sales Price',
            type: 'number',
            visible: false
        },
        {
            name: 'echoAfterDis',
            header: 'Echo After Discount',
            type: 'number',
            visible: false
        },
        {
            name: 'ftyPaymentDt',
            header: 'Factory Payment Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'grossAmount',
            header: 'Customer Invoice Value',
            type: 'number',
            visible: false
        },
        {
            name: 'grossPrice',
            header: 'Unit Gross Sales Price',
            type: 'number',
            visible: false
        },
        {
            name: 'licInvDt',
            header: 'Licensor Invoice Date',
            editable: true,
            filterable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'licInvNo',
            header: 'Licensor Invoice No',
            editable: true,
            filterable: true,
            type: 'text',
            visible: false
        },
        {
            name: 'licInvReqDt',
            header: 'Licensor Invoice Request Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'licPaymentDt',
            header: 'Licensor Payment Date',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'licPaymentDueDt',
            header: 'Licensor Payment Due',
            editable: true,
            type: 'date',
            visible: false
        },
        {
            name: 'marginAmount',
            header: 'Act. Total Margin',
            type: 'number',
            visible: false
        },
        {
            name: 'netAmount',
            header: 'Act. Net Sales/Amount Payable',
            type: 'number',
            visible: false
        },
        {
            name: 'netAmountPercent',
            header: 'Customer Invoice Discount',
            type: 'number',
            visible: false
        },
        {
            name: 'packingDiscount',
            header: 'Packing Discount Pc/Pack',
            type: 'number',
            visible: false
        },
        {
            name: 'paymentTerms',
            header: 'Payment Terms',
            type: 'text',
            visible: false
        },
        {
            name: 'royalityLicFee',
            header: 'Unit Royality Licensor Fee',
            type: 'number',
            visible: false
        },
        {
            name: 'settlementDiscountId',
            header: 'Settlement Discount',
            type: 'select',
            domain: ORDERS_DOMAIN_NAME,
            entity: SETTLEMENT_DISCOUNT,
            visible: false
        },
        {
            name: 'totalCommission',
            header: 'Act. Total Commission',
            type: 'number',
            visible: false
        },
        {
            name: 'totalFactoryValue',
            header: 'Act. Total Cost Value',
            type: 'number',
            visible: false
        },
        {
            name: 'totalMarginPercent',
            header: 'Act. Total Margin %',
            type: 'number',
            visible: false
        },
        {
            name: 'totalRoyalityLicensorFee',
            header: 'Act. Total Royality Licensor Fee',
            type: 'number',
            visible: false
        },
        {
            name: 'totalShippedFactoryCost',
            header: 'Act. Total Factory Cost',
            type: 'number',
            visible: false
        },
        {
            name: 'unitTotalCost',
            header: 'Unit Total Cost',
            type: 'number',
            visible: false
        },
        {
            name: 'batchRef',
            header: 'Batch Ref',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'orderNetAmount',
            header: 'Order Net Sales/Amount Payable',
            type: 'number',
            visible: false
        },
        {
            name: 'orderEchoInvoiceValue',
            header: 'Order Echo Invoice Value',
            type: 'number',
            visible: false
        },
        {
            name: 'orderFactoryInvoiceValue',
            header: 'Order Factory Invoice Value',
            type: 'number',
            visible: false
        },
        {
            name: 'orderTotalRoyalityLicensorFee',
            header: 'Order Total Royality Licensor Fee',
            type: 'number',
            visible: false
        },
        {
            name: 'orderCustomerInvoiceDiscount',
            header: 'Order Customer Invoice Discount',
            type: 'number',
            visible: false
        },
        {
            name: 'orderTotalCostValue',
            header: 'Order Total Cost Value',
            type: 'number',
            visible: false
        },
        {
            name: 'orderTotalMargin',
            header: 'Order Total Margin',
            type: 'number',
            visible: false
        },
        {
            name: 'orderTotalMarginPercent',
            header: 'Order Total Margin %',
            type: 'number',
            visible: false
        },
        {
            name: 'orderTotalFactoryCost',
            header: 'Order Total Factory Cost %',
            type: 'number',
            visible: false
        },
        {
            name: 'designDepartment',
            header: 'Design Group',
            type: 'select',
            domain: COMMON_DOMAIN_NAME,
            entity: DESIGN_DEPARTMENT,
            visible: false,
            filterable: true
        }
    ];
};
