import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Checkbox from '@baseComponents/Checkbox';

const SelectAllRow = ({ checked, onChange, classes }) => (
    <div className={classes.selectAllRow} onClick={onChange}>
        <Checkbox checked={checked} />
        <Typography className={classes.checkboxLabel}>Select All</Typography>
    </div>
);

SelectAllRow.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default SelectAllRow;
