import React from 'react';

import { withStyles } from '@material-ui/core';

import RetailerBreakdownSelect from './RetailerBreakdownSelect';
import ManageChartsVisibility from './ManageChartsVisibility';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        columnGap: spacing.unit * 2
    }
});

const Adornments = withStyles(styles)(({ classes }) => {
    return (
        <div className={classes.root}>
            <ManageChartsVisibility />
            <RetailerBreakdownSelect />
        </div>
    );
});

export default Adornments;
