import React, { useCallback } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { LegendItem } from '@libComponents/Charts';
import { withModalProvider, withModal } from '@libComponents/Modal';

import OverlayChartContainer from '../../../../OverlayChartContainer';
import DetailViewModal from './DetailViewModal/';

const styles = ({ spacing, palette }) => ({
    legendContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    itemContainer: {
        '&:hover': {
            cursor: 'pointer',
            '& $legendValue': {
                color: palette.orange.main
            },
            '& $legendName': {
                color: palette.orange.main
            }
        }
    },
    colorBox: {
        width: spacing.unit * 2,
        height: spacing.unit * 2,
        marginRight: spacing.unit * 0.75
    },
    legendName: {
        maxWidth: 'var(--name-max-width)',
        fontSize: spacing.unit * 1.5,
        marginRight: spacing.unit * 1
    },
    legendValue: {
        fontSize: spacing.unit * 1.5,
        marginRight: spacing.unit * 0.75
    }
});

const LegendContainer = ({
    classes: c,
    theme: { spacing },
    payload,
    screen,
    height,
    handleModal
}) => {
    const maxWidth =
        screen === 'lg'
            ? spacing.unit * 20
            : screen === 'md'
            ? spacing.unit * 17
            : spacing.unit * 12;

    const onLegendItemClick = useCallback(
        (label, key, quantity) => {
            return handleModal({ label, columnName: key, quantity });
        },
        [handleModal, payload]
    );

    return (
        <OverlayChartContainer
            style={{
                '--max-height': height - 20 + 'px',
                '--name-max-width': maxWidth + 'px'
            }}
        >
            <div className={c.legendContainer}>
                {payload.map((item, index) => (
                    <div
                        key={item.value}
                        className={c.itemContainer}
                        onClick={() =>
                            onLegendItemClick(
                                item.value,
                                item.payload.key,
                                item.payload.value
                            )
                        }
                    >
                        <LegendItem
                            color={item.color}
                            name={item.value}
                            value={item.payload.value}
                            styles={{
                                colorBox: c.colorBox,
                                value: c.legendValue,
                                name: c.legendName
                            }}
                        />
                    </div>
                ))}
            </div>
        </OverlayChartContainer>
    );
};

export default compose(
    withModalProvider,
    withModal({
        handleModal: DetailViewModal
    }),
    withStyles(styles, { withTheme: true })
)(LegendContainer);
